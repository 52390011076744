import ReactDOMServer from "react-dom/server";
import React, { useContext } from "react";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";

// utils
import { generateDocument } from "../fileHandlers/docxTemplater";

// Context
import { Context } from "../../index";

// Components
import { WelcomeUA, WelcomeUAText } from "../../database/emails/WelcomeUA";
import {
  WelcomeUA25,
  WelcomeUA25Text,
} from "../../database/emails/WelcomeUA25";
import {
  WelcomeUA100,
  WelcomeUA100Text,
} from "../../database/emails/WelcomeUA100";

export const SendEmail = async (userData, emailType) => {
  const { firestore, storage } = useContext(Context);
  const mailCollection = collection(firestore, "mail");
  const emailAddresses =
    userData.relativeEmail && userData.relativeEmail !== "Не вказано"
      ? [userData.email, userData.relativeEmail]
      : [userData.email];

  // Формуємо пропси для email
  const emailProps = {
    name: userData.displayName || "Студент", // Ім'я студента
    fullPrice: 1200, // Базова ціна навчання
    discountPrice: 1200 * 0.75, // Ціна після 25% знижки
    registrationFee: 87, // Реєстраційний збір для гранту
  };

  // Визначаємо, який лист відправляти
  let emailHtml, emailText;

  if (emailType === "discount25") {
    emailHtml = ReactDOMServer.renderToStaticMarkup(
      <WelcomeUA25 {...emailProps} />
    );
    emailText = WelcomeUA25Text(emailProps);
  } else if (emailType === "discount100") {
    emailHtml = ReactDOMServer.renderToStaticMarkup(
      <WelcomeUA100 {...emailProps} />
    );
    emailText = WelcomeUA100Text(emailProps);
  } else {
    emailHtml = ReactDOMServer.renderToStaticMarkup(
      <WelcomeUA {...emailProps} />
    );
    emailText = WelcomeUAText(emailProps);
  }

  // ГЕНЕРУЄМО ЛИСТА
  generateDocument(userData, "invoice", firestore, async (blob) => {
    // Формуємо дату у форматі dd.mm.yyyy
    const newDate = new Date();
    const day = String(newDate.getDate()).padStart(2, "0");
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const year = newDate.getFullYear();
    const formattedDate = `${day}.${month}.${year}`;

    // Завантажуємо документ у Firebase Storage
    const storageRef = ref(
      storage,
      `invoices/${userData.displayName}-${formattedDate}.docx`
    );

    try {
      await uploadBytes(storageRef, blob);
      const downloadURL = await getDownloadURL(storageRef);

      // Формуємо email для Firestore
      const emailData = {
        to: emailAddresses,
        message: {
          subject: "INTERNATIONAL ACADEMY MBO",
          text: emailText,
          html: emailHtml,
          //replyTo: "inacademymbo.post@gmail.com",
          attachments: [
            {
              filename: "invoice.docx",
              path: downloadURL,
            },
          ],
        },
      };

      // Додаємо email у Firestore
      await addDoc(mailCollection, emailData);
      console.log("Registration email has been added to the collection.");
      toast.success("Check your email");
    } catch (error) {
      console.error("Error adding registration email: ", error);
      toast.error("Error adding registration email.");
    }
  });
};
