import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useScrollPosition } from "../../utils/dom/scroll";
import { openHandler, closeHandler } from "../../utils/dom/open-close-modal";
import { openVideoLink } from "../../database/commonData";
import heyDutch from "../../lib/logo-icons/HeyDutch-163x200.png";
import "./burgerStyle.scss";

export const Burger = () => {
  const navigate = useNavigate();
  const headerResize = useScrollPosition();
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const [menuOpen, setMenuOpen] = useState(null); // Для одночасного відкриття тільки одного меню
  const menuRefs = useRef([]); // Використання useRef для зберігання посилань на меню
  const { t } = useTranslation();

  const handleMenuClick = (event) => {
    const { type, id, key } = event.currentTarget.dataset;
    switch (type) {
      case "course":
        navigate(`/${key}`);
        setShowBurgerMenu(false);
        closeHandler(); // Виклик розблокування прокрутки після навігації
        break;

      case "navigate":
        navigate(`/${id}`);
        setShowBurgerMenu(false);
        closeHandler(); // Виклик розблокування прокрутки після навігації
        break;

      case "link":
        openVideoLink(); // Викликаємо хендлер для посилання на відео
        setShowBurgerMenu(false);
        closeHandler(); // Виклик розблокування прокрутки після навігації
        break;

      default:
        break;
    }
  };

  const toggleMenu = (index) => {
    setMenuOpen((current) => (current === index ? null : index)); // Одночасне відкриття тільки одного меню
  };

  const toggleBurgerMenu = () => {
    if (showBurgerMenu) {
      closeHandler(); // Розблокувати прокрутку, якщо меню закривається
    } else {
      openHandler(); // Заблокувати прокрутку, якщо меню відокремлюється
    }
    setShowBurgerMenu(!showBurgerMenu);
  };

  useEffect(() => {
    menuRefs.current.forEach((menuRef, index) => {
      if (menuRef) {
        menuRef.style.setProperty("--max-height", `${menuRef.scrollHeight}px`);
      }
    });
  }, [menuOpen]);

  const menuData = [
    {
      title: "courses",
      items: [
        //  { id: "1", key: "lang_dutch_course" },
        { id: "2", key: "integration_course" },
        { id: "3", key: "pro_hotel" },
        { id: "4", key: "pro_restaurant" },
        { id: "7", key: "pro_retail" },
        { id: "5", key: "pro_logistics" },
        { id: "8", key: "pro_food_industry" },
        { id: "6", key: "pro_entrepreneurship" },
        { id: "7", key: "pro_economy" },
      ].map((course) => ({ ...course, type: "course" })),
    },
    {
      title: "contacts",
      items: [
        { key: "email", text: "inacademyMBO.post@gmail.com" },
        {
          key: "address",
          text: `UP Office Building. Piet Heinkade 55, 1019 GM Amsterdam, ${t(
            "address_mbo"
          )}`,
        },
      ],
    },
    {
      title: "our_partners",
      items: [
        {
          type: "partner",
          imgSrc: heyDutch,
          link: "https://www.heydutch.com/",
          text: t("our_leading_partner"),
        },
      ],
    },
  ];

  const singleMenuItems = [
    { title: "instruction", type: "link" },
    { title: "faq", type: "navigate", id: "faq" },
    { title: "news", type: "navigate", id: "news" },
    { title: "about_us", type: "navigate", id: "about_us" },
  ];

  return (
    <>
      <div
        className={`burger ${showBurgerMenu ? "burger--open" : ""}`}
        onClick={toggleBurgerMenu}
      >
        <div className="burger__line"></div>
        <div className="burger__line"></div>
        <div className="burger__line"></div>
      </div>

      <section
        className={`burger__container ${
          showBurgerMenu ? "burger__container--open" : ""
        } ${headerResize ? "" : "burger__container--short"}`}
      >
        {menuData.map((menu, index) => (
          <div key={index} className="burger__menu-item">
            <div
              className="burger__menu-title"
              onClick={() => toggleMenu(index)}
            >
              {t(menu.title)}
              {menu.items.length > 0 && (
                <span
                  className={`arrow ${menuOpen === index && "arrow--turn"}`}
                ></span>
              )}
            </div>
            {menu.items.length > 0 && (
              <div
                className={`burger__menu-content ${
                  menuOpen === index && "open"
                }`}
                ref={(el) => (menuRefs.current[index] = el)}
              >
                {menu.items.map((item, itemIndex) => (
                  <div
                    key={itemIndex}
                    className="burger__menu-element"
                    data-type={item.type || "info"}
                    data-id={item.id}
                    data-key={item.key}
                    onClick={handleMenuClick}
                  >
                    {item.type === "partner" ? (
                      <div
                        className="burger__partner"
                        onClick={() => window.open(item.link, "_blank")}
                      >
                        <img
                          className="burger__partner-img"
                          src={item.imgSrc}
                          alt="partner img"
                        />
                        <p className="burger__partner-text">{item.text}</p>
                      </div>
                    ) : item.text ? (
                      `${t(item.key)}: ${item.text}`
                    ) : (
                      t(item.key)
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}

        {singleMenuItems.map((item, index) => (
          <div
            key={index}
            className="burger__menu-item"
            data-type={item.type}
            data-id={item.id}
            onClick={handleMenuClick}
          >
            <div className="burger__menu-title">{t(item.title)}</div>
          </div>
        ))}
      </section>
    </>
  );
};
