export const WelcomeUA25 = (props) => {
  const { name, fullPrice, discountPrice } = props;

  return (
    <div>
      <html>
        <body
          style={{
            fontFamily: "Arial, sans-serif",
            lineHeight: "1.6",
            color: "black",
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "10px",
            margin: "0",
          }}
        >
          <div style={{ width: "700px", margin: "auto", color: "black" }}>
            <div
              style={{
                textAlign: "center",
                marginBottom: "20px",
                color: "black",
              }}
            >
              <img
                src="https://myplaylist2023.s3.eu-north-1.amazonaws.com/logo3.png"
                height="70px"
                width="70px"
                alt="Company Logo"
              />
              <h1
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  margin: "0",
                  color: "black",
                }}
              >
                INTERNATIONAL ACADEMY MBO
              </h1>
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "10px",
                  color: "#555",
                  lineHeight: "1.4",
                }}
              >
                1019 GM, Piet Heinkade 55, Amsterdam, Netherlands
                <br />
                Email:{" "}
                <a
                  href="mailto:inacademyMBO.post@gmail.com"
                  style={{ color: "#007BFF", textDecoration: "none" }}
                >
                  inacademyMBO.post@gmail.com
                </a>
                <br />
                Website:{" "}
                <a
                  href="https://www.inacademy.nl"
                  style={{ color: "#007BFF", textDecoration: "none" }}
                >
                  www.inacademy.nl
                </a>
              </p>
            </div>
            <hr
              style={{
                border: "none",
                borderBottom: "2px solid #ddd",
                marginBottom: "20px",
              }}
            />
            <h2
              style={{
                textAlign: "center",
                fontSize: "20px",
                marginBottom: "20px",
                color: "black",
              }}
            >
              {name}, вітаємо зі вступом до
              <br /> INTERNATIONAL ACADEMY MBO.
            </h2>
            <p
              style={{
                marginBottom: "20px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Ми раді бачити вас серед наших студентів.
            </p>
            <p
              style={{
                marginBottom: "20px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Протягом наступних п’яти років ви можете опанувати нові знання,
              скласти екзамени та подати заявку на стажування в одній з
              європейських компаній.
            </p>
            <p
              style={{
                marginBottom: "20px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Вам надано <strong>знижку 25%</strong>, тому вартість навчання для
              вас складає <strong>{discountPrice} євро</strong> замість{" "}
              <strong>{fullPrice} євро</strong>.
            </p>
            <p
              style={{
                marginBottom: "20px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Якщо ви хочете розбити платіж на 10 частин - активуйте функцію
              «Оплата в розстрочку» в особистому кабінеті.
            </p>
            <p
              style={{
                marginBottom: "20px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Ваш Договір про навчання активується після здійснення першого
              платежу.
            </p>
            <p
              style={{
                marginBottom: "20px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Рахунок (Invoice) ми вже відправили на вашу електронну пошту.
            </p>
            <p
              style={{
                marginBottom: "20px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Бажаємо вам успішного навчання! Якщо будуть запитання – ми завжди
              на зв'язку.
            </p>
            <table style={{ width: "100%", marginTop: "30px" }}>
              <tbody>
                <tr>
                  <td style={{ width: "50%", color: "black" }}>
                    <p style={{ fontWeight: "bold", color: "black" }}>
                      З повагою,
                      <br />
                      Команда INTERNATIONAL ACADEMY MBO
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </body>
      </html>
    </div>
  );
};

export const WelcomeUA25Text = (props) => {
  const { name, fullPrice, discountPrice } = props;

  return `
  ${name}, вітаємо зі вступом до INTERNATIONAL ACADEMY MBO.

  Ми раді бачити вас серед наших студентів.
  Протягом наступних п’яти років ви можете опанувати нові знання, скласти екзамени та подати заявку на стажування в одній з європейських компаній.
  Вам надано знижку 25%, тому вартість навчання для вас складає ${discountPrice} євро замість ${fullPrice} євро.
  Якщо ви хочете розбити платіж на 10 частин - активуйте функцію «Оплата в розстрочку» в особистому кабінеті.
  Ваш Договір про навчання активується після здійснення першого платежу.
  Рахунок (Invoice) ми вже відправили на вашу електронну пошту.
  Бажаємо вам успішного навчання! Якщо будуть запитання – ми завжди на зв'язку.

  З повагою,
  Команда INTERNATIONAL ACADEMY MBO
  `;
};
